import React, { useContext, useEffect, useState } from 'react'

export interface IContactContext {
    session: {
        s: string, 
        c: string
    },
    setData: (token:any)=>void,
    reload: ()=>void
}
export const ContactContext = React.createContext<IContactContext>({
    session: { s: '', c: ''},
    setData: (token)=>{},
    reload: ()=>{}
})

export const ContactCtx = ({children}:any)=>{
    const [session, setToken] = useState<any>({ s:'', c:''})

    const reload = async ()=>{
        const r = await fetch(process.env.REACT_APP_CAPTCHA_URL + 'getToken',{
            headers: {
                accept: 'application/json',
            }
        }).then(r=>r.json()).catch(r=>r)

        
        setToken({ s: r.t, c: r.c})

    }
    
    useEffect(()=>{
        
        reload()

    }, [])

    return <ContactContext.Provider value={{session, setData: setToken, reload }}>
            {children}
    </ContactContext.Provider>
}

export const useContact = ()=>{
    return useContext(ContactContext)
}