import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText } from 'reactstrap'
import { useLayout } from '..'
import { useData } from '../../Data/DataContext'
import { AllRoutes } from '../../Route/allRoutes'
import Anouncement from '../../Views/Anouncement'

export default function Navigation() {
    const [show, setShow] = useState(false);
    const { logo } = useData()
    const { navHide } = useLayout()


  return (
      <div className='navbars'>
      {/* <Navbar className='anouncement'>
          <Nav >
        <Anouncement />
        </Nav>
      </Navbar> */}
    <Navbar

        className={'site-nav ' + (navHide?'d-none': '')}
        color="light"
        expand="md"
        light
    >
        
        <NavbarBrand href="/">
        <img className='brand-logo' src={logo} />
        </NavbarBrand>
        <NavbarToggler onClick={()=>setShow(!show)} />
        <Collapse navbar className={(show?'show':'')+ ''}>
        <Nav
            className="me-auto"
            navbar
        >
            {AllRoutes.filter(r=>!r.hideNav).map((r,i)=>{
                return   <NavItem key={i}>
                            <NavLink to={r.link}>
                                {r.label}
                            </NavLink>
                        </NavItem>
            })}
          
            
            {/* <UncontrolledDropdown
            inNavbar
            nav
            >
            <DropdownToggle
                caret
                nav
            >
                Options
            </DropdownToggle>
            <DropdownMenu end>
                <DropdownItem>
                Option 1
                </DropdownItem>
                <DropdownItem>
                Option 2
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                Reset
                </DropdownItem>
            </DropdownMenu>
            </UncontrolledDropdown> */}
        </Nav>
        <NavbarText>
            
        </NavbarText>
        </Collapse>
    </Navbar>
    </div>
  )
}


