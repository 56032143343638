import { faBoreHole, faBottleDroplet, faHillRockslide, faTree } from '@fortawesome/free-solid-svg-icons'
import React, { useContext, useState } from 'react'
import imgCarbon from '../assets/img/fotos/MAX_0079.jpg'
//import imgCarbon from '../assets/img/carbon.jpeg'
//import imgCoque from '../assets/img/coque.jpeg'
import imgCoque from '../assets/img/fotos/MAX_0032.jpg'
//import imgSulfato from '../assets/img/sulfatodeamonio.jpeg'
import imgSulfato from '../assets/img/fotos/MAX_0055.jpg'
import imgAlquitran from '../assets/img/alquitran.jpg'
import bgLobatera2 from '../assets/img/lobatera2.jpeg'

import bgLobatera from '../assets/img/minalobatera.webp'
import logoWhite from '../assets/img/logo/LOGOCOQUETA02-02_1.png'
import beforeContact from '../assets/img/fotos/MAX_0010.jpg'
//import contact from '../assets/img/contact1.jpeg'
import contact from '../assets/img/phone.jpg'

// import Foto1 from '../assets/img/cooqueta/foto1.jpg';
// import Foto2 from '../assets/img/cooqueta/foto2.jpg';
// import Foto3 from '../assets/img/cooqueta/foto3.jpg';
// import Foto4 from '../assets/img/cooqueta/foto4.jpg';
// import Foto5 from '../assets/img/cooqueta/foto5.jpg';
// import Foto6 from '../assets/img/cooqueta/foto6.jpg';
// import Foto7 from '../assets/img/cooqueta/foto7.jpg';
// import Foto8 from '../assets/img/cooqueta/foto8.jpg';
// import Foto9 from '../assets/img/cooqueta/foto9.jpg';

// import Video1 from '../assets/video/caverna_edit.mp4'
// import Video2 from '../assets/video/tren1.mp4'
// import Video3 from '../assets/video/tunel1.mp4'
// import Video4 from '../assets/video/caverna12.mp4'
import { BlockData } from '../Views/Blocks/BlockData'

import Slider from '../assets/img/fotos/slider1.jpeg'
import Slider2 from '../assets/img/fotos/slider2.jpeg'
import Slider3 from '../assets/img/fotos/slider3.jpeg'
//import Slider2 from '../assets/img/fotos/MAX_0066.jpg'
import SpecsImage from '../assets/img/fotos/SPECS.jpeg'
import RecCarbon from '../assets/img/fotos/RECOLECCION.jpeg'
import LocationImage from '../assets/img/fotos/MAX_0061.jpg'
import useRequest from './useRequest'
export interface IDataContext {
    captchaUrl?: string,
    logo: any;
    siteName: string,
    motto: string,
    blocks: { 
        title: string, 
        subtitle: string,
        description: string,
        blocks: BlockData[]
    },
    sliderImg: {
        image: any,
        video?: any
    }[],
    availability: {
        title: string,
        image?:any,
        color?:string,
        description:  string
    },
    specs: {
        title: string,
        description: string,
        image: any,
        datasheetTitle : string, 
        datasheet: any
    }
    location: {
        title: string,
        description: string,
        image: any,
        color?: string,
        locationButtonText: string,
        coords: {
            lat: number,
            lng: number
        }
    },
    contact: {
        title: string,
        image: any,
        text: string,
        beforeTitle1:string,
        beforeContent1:string,
        beforeTitle2:string,
        beforeContent2:string,
        instagram: string,
        facebook: string,
        beforeImage: any,
        contactEmail: any

    },
    resetPassword: {
        formLabelText: string,
        setNewPassword: string, 
        confirmPassword: string,
        activateError: string
    },
    request: any
}

const empty= ():IDataContext=>({
    captchaUrl: process.env.CAPTCHA_URL,
    logo: logoWhite,
    siteName: 'Coque del Táchira Corp, c.a.',
    motto: '',

    blocks: { 
        title: 'PROCESO EN MINA', 
        subtitle: '',
        description: `
        Bajo el concepto de industrialización del carbón,poseemos la capacidad de extraer y explotar la materia prima, así como también de recolectar el que de forma artesanal se genera en zonas cercanas a las Minas de Lobatera; carbón  tipo A y tipo B, esto con el propósito de poder comercializar no solo a nivel nacional, también a nivel internacional.
        <br />
        Cabe destacar que a través de esta actividad en el sector de Lobatera generamos miles de empleos, así como también mejoras en la comunidad, hacemos todo esto posible en colaboración con alianzas estratégicas nacionales. 
        `, 
        blocks: [
            {
                title: 'Comercializacion',
                icon: faHillRockslide,
                description: '',
                image: imgCarbon,
                //video: Video4

            },
            {
                title: 'Aliados',
                icon: faBoreHole,
                description: '',
                image: imgCoque,
                //video: Video2
            },
            {
                title: 'Táchira',
                icon: faTree,
                description: '',
                image: imgSulfato,
                //video: Video3
            },
            // {
            //     title: 'Procesamiento',
            //     icon: faBottleDroplet,
            //     description: '',
            //     image: imgAlquitran,
            //     video: Video4
            // }

        ]},
    //sliderImg: { image: Foto4, video: Video1 },
    sliderImg: [
        { image: Slider},  
        { image: Slider2},
        { image: Slider3}
    ],
    availability: { 
        title: 'Recoleción de Carbón',
        image: RecCarbon,
        color: '#000',
        description: 'Dichas reservas probadas de carbón indicadas en el particular anterior, de ser explotadas tres Millones (3.000.000 TM/año), determinaría la disponibilidad de 20 años de vida útil del yacimiento mineral, de poder aumentarla explotación, la disponibilidad seria por 10 años sin contar las reservas probables que elevarían el tiempo en un 50% en cualquiera de las dos circunstancias.'
    },
    specs: {
        title: 'Carbones del Táchira',
        description: 'Los carbones del Táchira son los que presentan las mejores características en su composición para la obtención de coque metalúrgico. Esta condición les otorga una importante ventaja comercial, pues no sólo se garantiza la venta directa del carbón extraído, sino que la misma empresa que lo explote puede, con las instalaciones necesarias, convertirlo en coque metalúrgico, obteniendo por su venta un precio tres veces mayor al del carbón. Aparte de su potencial de estar ubicado en una zona de frontera lo que sin duda nos pone en unas condiciones inmejorables, independientemente de las circunstancias actuales esta frontera es la más viva de América Latina.',
        image: SpecsImage,
        datasheetTitle: 'Descarga nuestra ficha técnica',
        datasheet: '/assets/pdf/brochure.pdf'
    },
    location: {
        title: 'Ubicación',
        //description: 'Asentamiento Campesino “Las Adjuntas”, de los municipios Bolívar y Junín, sector Las Adjuntas, en las afueras de las poblaciones de Rubio y San Antonio del estado Táchira. La parcela en donde se desarrolla el proyecto minero dista, en línea recta: 6 km de Rubio (capital del Municipio Junín). 7 km de San Antonio del Táchira. 7 km de la frontera (Colombia-Venezuela). 10 km de Capacho Viejo (capital del Municipio Libertad). Lote de terreno con dimensión de 1.100 Hectáreas.',
        description: 'Estado Táchira, Venezuela',
        image: LocationImage,
        color: '#60a601',
        locationButtonText: 'Ver en mapa',
        coords: {
            lat: 7.9298109,
            lng: -72.2423831,
        }
    },
    contact: {
        title: 'Contáctanos',
        image: contact,
        text:'',
        beforeTitle1: 'CONTACTANOS',
        beforeContent1: 'Para comercialización, rellena el siguiente formulario y nos pondremos en contacto contigo lo antes posible.',
        beforeTitle2: 'SIGUENOS',
        beforeContent2: '',
        instagram: 'instagramlink',
        facebook: 'facebooklink',
        beforeImage: '',
        contactEmail: "Coquetachiracorp@gmail.com"
    
    },
    resetPassword: {
        formLabelText: 'Ingrese su correo electrónico Corporativo Coqueta para proceder con el reseteo de contraseña',
        setNewPassword: 'Ingrese su nueva contraseña',
        confirmPassword: 'Confirme su nueva contraseña',
        activateError: 'Error validar su link de reseteo de contraseña'
    },
    request: { post: ()=>{},
    put: ()=>{},
    get: ()=>{},
    delete: ()=>{}}


})

export const DataContext = React.createContext<IDataContext>(empty())


export const Data = ({children}:any)=>{
    const [data, setData] = useState<IDataContext>(empty())

    const request = useRequest()

    return <DataContext.Provider value={{ ...data, request }}>
        {children}
    </DataContext.Provider>
}

export function useData(){
    return useContext(DataContext)
}