import React from 'react'
import { useLayout } from '../Layout'
import Availability from './Availabiliy'
import Blocks from './Blocks'
import PhotoSlider from './PhotoSlider'
import Specs from './Specs'
import Location from './Location'
import Contact from './Contact'
import BeforeContact from './Contact/BeforeContact'

export default function Landing() {
    const { contentHeight } = useLayout()
  return (
    <>
    <PhotoSlider />
    <Blocks />
    <Availability />
    <Location />
    <Specs />
    <BeforeContact />
    <Contact />
    </>
   
  )
}
