import { faMapLocation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap';
import { useData } from '../../Data/DataContext';
import { useLayout } from '../../Layout';

export default function Location() {
    const { contentHeight: height } = useLayout()
    const { location } = useData();
    const { title, description, image, color, coords, locationButtonText} = location;

  
    return <Container fluid={true} className="location" style={{ height }}>
        <Row className='h-100 p-0'>
            {/* <Col sm={6} className="sidea -0 ">
                <div className='m-auto'>
                    <div className='m-auto mb-0 icon'> <FontAwesomeIcon icon={faMapLocation} /></div>
                    <a className='btn btn-outline-secondary mt-0' target='blank' href={`https://www.google.com/maps/search/?api=1&query=${location.coords.lat}%2C${location.coords.lng}`} >
                        {locationButtonText}
                    </a> 
                </div>
            
            </Col> */}
            <Col sm={12} className="sideb h-100 p-0 midsection" style={{background: color}} >
              { image && <div className='image'><img src={image} /></div> }
              <div className='text-container'>
                <div className='title'><FontAwesomeIcon icon={faMapLocation} />{title}</div>
                <div className='description'>{description}</div>
              </div>
              
            </Col>
        </Row>
    </Container>
}
