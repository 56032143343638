import React, { useEffect, useState } from 'react'
import { useLayout } from '../../Layout'
import HeaderImg from '../../assets/img/1944450.jpg'
import { useData } from '../../Data/DataContext'
import { isBrowser, isMobile } from 'react-device-detect'

export default function PhotoSlider() {

    
    const videoRef = React.useRef<HTMLVideoElement>(null)
    const { contentHeight } = useLayout()
     
    const { logo, sliderImg, siteName, motto } = useData()
    const [ currentSliderImg, setCurrentImage] = useState(sliderImg[0])


    const { image, video} = currentSliderImg

    useEffect(()=>{
        let counter = 0;
        const interval = setInterval(()=>{
            counter++;
            if(counter == sliderImg.length){
                counter = 0;
            }
            setCurrentImage(sliderImg[counter])
        },6000)

        return ()=>clearInterval(interval)

    }, [sliderImg])

    useEffect(()=>{
        if(videoRef.current){
            videoRef.current.play()
            videoRef.current.volume = 0
            videoRef.current.loop = true
        }
    }, [videoRef.current])
  return (
    <div className='slider-img' style={{ height: contentHeight}}>
        { isBrowser && sliderImg.map((s,i)=><div key={i} className={'w-100 header-img-overlay ' + (image == s.image?'visible': 'hidden')} style={{ height: contentHeight}}>
            {!s.video && <img src={s.image} className="w-100 h-100 header-img" /> }
            {s.video && <video ref={videoRef} src={s.video} className="w-100 h-100 header-img" /> }
            <div className='text'>
                
                <div className='text-content'>
                    <div className='title'>{siteName}</div>
                    <div className='subtitle'>{motto}</div>
                </div>
            </div>
        </div>) }
        { isMobile && <div className='w-100 header-img-overlay' style={{ height: contentHeight}}>
            {<img src={image} className="w-100 h-100 header-img" /> }
            {/* {video && <video ref={videoRef} src={video} className="w-100 h-100 header-img" /> } */}
            <div className='text'>
                
                <div className='text-content'>
                    <div className='title'>{siteName}</div>
                    <div className='subtitle'>{motto}</div>
                </div>
            </div>
        </div> }

    </div>
  )
}
