import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Col, FormGroup, Label, Input, Button, Row } from "reactstrap";
import { useData } from "../../Data/DataContext";
import { useValidateActivation } from "./ValidateActivation";
import { useValidPassword } from "./ValidatePassword";

export default function Activate({ activateToken, onRejectValidation }: any) {
  const [validating, setValidating] = useState("check");
  const navigate = useNavigate();
  const { resetPassword, request } = useData();
  const [passObj, setPassObj] = useState<any>({ password: "", confirm: "" });

  const [sent, setSent] = useState(false);

  // const n = Math.random()*10
  // const validateActivate = (token:string)=>
  // new Promise((resolve, reject)=>{ setTimeout(()=>{

  //     if(n > 5){ resolve(n) }
  //     else{ reject(n)}
  // }, 5000)})

  const changePassword = () => {};

  // useEffect(()=>{
  //     validateActivate(activateToken).then(()=>setValidating('accept')).catch(onRejectValidation)
  // },[])

  const submit: React.FormEventHandler<HTMLFormElement> = (e)=>{
    e.preventDefault();
    request.post('resetInternalEmail', {
        token: activateToken,
        ...passObj
    }).then((e:any)=>{
        console.log(e)
        setSent(true)
    }).catch((e:any)=>{
        console.log(e)
    })
  }
  const activation = useValidateActivation(activateToken);

  useEffect(() => {
    console.log(activation);
    if (activation.loaded && !activation.valid) {
      navigate("/password-reset");
    }
  }, [activation.valid]);

  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setPassObj({ ...passObj, [event.target.name]: event.target.value });
  };

  const { valid: passValid, helpText } = useValidPassword(passObj);
  return (
    <div>
      <>{!activation.loaded && <div>Estamos validando su activación</div>}</>
      <>
        {!!activation.loaded && activation.valid && (
          <div>
            <Row>
              <Form onSubmit={submit}>
                <Col sm={12}>
                  <FormGroup>
                    <Label for="password">{resetPassword.setNewPassword}</Label>
                    <Input
                      disabled={sent}
                      name="password"
                      onChange={changeHandler}
                      value={passObj.password}
                      type="password"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="reset-email">
                      {resetPassword.confirmPassword}
                    </Label>
                    <Input
                      disabled={sent}
                      name="confirmPassword"
                      onChange={changeHandler}
                      value={passObj.confirmPassword}
                      type="password"
                    />
                  </FormGroup>
                  <div className="help-text">{helpText} </div>
                  { sent && <div>Cambio exitoso de contraseña</div>}
                  <Button disabled={sent || !passValid}>Enviar</Button>
                </Col>
              </Form>
            </Row>
          </div>
        )}
      </>
    </div>
  );
}
