import { faPaperclip, faRectangleList, faSheetPlastic } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import { useData } from '../../Data/DataContext'
import { useLayout } from '../../Layout'

export default function Specs() {

  const { contentHeight: height } = useLayout()
  const { specs } = useData();
  const { title, description, image, datasheet, datasheetTitle} = specs;


  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
      e.preventDefault();
      const link = document.createElement('a');
      link.href = datasheet
      link.download = 'datasheet.pdf'
      link.target = 'blank'
      link.click()
  }


  return <Container fluid={true} className="specs" style={{ height }}>
      <Row className='h-100'> 
          <Col sm={6} className="sidea p-0 midsection">
              <div className='image'><img src={image} /></div>
              <div className='text-container'>
                <div className='title'>{title}</div>
                <div className='description'>{description}</div>
              </div>
          </Col>
          <Col sm={6} className="sideb p-0">
              <div className='m-auto'>
                <div className='icon m-auto mb-0'><FontAwesomeIcon icon={faRectangleList} /></div>
                <Button onClick={e=>handleClick(e)} className="datasheet mt-0" outline={true}>
                    {datasheetTitle}
                </Button>
              </div>
              
          </Col>
      </Row>
  </Container>
}
