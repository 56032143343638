import React, { useEffect, useState } from 'react'

export default function useRequest() {
    const [session, setToken] = useState<any>({ s:'', c:''})

    const reload = async ()=>{
        const r = await fetch(process.env.REACT_APP_CAPTCHA_URL + 'getToken',{
            headers: {
                accept: 'application/json',
            }
        }).then(r=>r.json()).catch(r=>r)

        
        setToken({ s: r.t, c: r.c})

    }

    useEffect(()=>{
        reload()
    },[])

    const post = (ep:string, data:any)=>new Promise((resolve,reject)=>{
        let xhr = new XMLHttpRequest();
        xhr.open('POST', process.env.REACT_APP_CAPTCHA_URL + ep)
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('auth', session.s)
        xhr.send(JSON.stringify(data))
        xhr.onerror = (event:any)=>{
            reject(event)
        }
        xhr.onloadend = (event:any) =>{
            if(xhr.status == 200 || xhr.status == 201){
                console.log('Success POST CALL')
                
            }
            resolve(JSON.parse(event.target.response))

        }
    })
    const put = (ep:string, data:any)=>new Promise((resolve,reject)=>{
        let xhr = new XMLHttpRequest();
        xhr.open('PUT', process.env.REACT_APP_CAPTCHA_URL + ep)
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('auth', session.s)
        xhr.send(JSON.stringify(data))
        xhr.onerror = (event:any)=>{
            reject(event)
        }
        xhr.onloadend = (event:any) =>{
            if(xhr.status == 200 || xhr.status == 201){
                //console.log('Success', event)
                resolve(JSON.parse(event.target.response))
            }

        }
    })

    const get = (ep:string)=>new Promise((resolve,reject)=>{
        let xhr = new XMLHttpRequest();
        xhr.open('GET', process.env.REACT_APP_CAPTCHA_URL + ep)
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('auth', session.s)
        xhr.send()
        xhr.onerror = (event:any)=>{
            reject(event)
        }
        xhr.onloadend = (event:any) =>{
            if(xhr.status == 200 || xhr.status == 201){
                //console.log('Success', event)
                resolve(JSON.parse(event.target.response))
            }

        }
    })
    const deleteMethod = (ep:string)=>new Promise((resolve,reject)=>{
        let xhr = new XMLHttpRequest();
        xhr.open('DELETE', process.env.REACT_APP_CAPTCHA_URL + ep)
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('auth', session.s)
        xhr.send()
        xhr.onerror = (event:any)=>{
            reject(event)
        }
        xhr.onloadend = (event:any) =>{
            if(xhr.status == 200 || xhr.status == 201){
                //console.log('Success', event)
                resolve(JSON.parse(event.target.response))
            }

        }
    })
  
    return { post, get, put, delete:deleteMethod}
  
}
