import React, { useEffect, useState } from 'react'
import { FormGroup, Input } from 'reactstrap'
import { useContact } from './ContactContext'

export default function Captcha({ value, changeHandler}:any) {
    const { setData, session, reload } = useContact()
    
    

    console.log(session.s)
  return (
    <div>
        
        <FormGroup style={{ display: 'flex'}} >
            <img onClick={()=>reload()} src={session.c} className="captcha"/>
            <Input name='captcha' value={value} onChange={changeHandler} type='text' />
        </FormGroup>

    </div>
  )
}


