import React, { useEffect, useState } from 'react'
import { usePassword } from './PasswordContext'

export default function ValidateActivation() {
  return (
    <div>ValidateActivation</div>
  )
}


export const useValidateActivation = (token: string)=>{

    const [loaded, setLoaded] = useState({ valid: true} as any)
    const { session } = usePassword()

    useEffect(()=>{
        let xhr = new XMLHttpRequest();
        xhr.open('POST', process.env.REACT_APP_CAPTCHA_URL + 'resetInternalEmail')
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('auth', session.s)
        xhr.send(JSON.stringify({
            'activate': token
        }))
        xhr.onerror = (event:any)=>{
            console.log('Error', event.target?.response)
        }
        const loadend = (event:any) =>{
           // console.log(event)
            if(xhr.status == 200 || xhr.status == 201){
                const data = JSON.parse(event.target.responseText)
             //   console.log('Success', data)
                setLoaded(data)
            }

        }

        xhr.onloadend = loadend
        return ()=>{
            xhr.removeEventListener('onloadend', loadend)
        }
    },[token])

    return { valid:loaded.valid, loaded }
}