import React from "react";
import Contact from "../Views/Contact";
import Landing from "../Views/Landing";
import PasswordReset from "../Views/PasswordReset";

export const AllRoutes = [
    { link: '/', label: 'Home', Component: Landing  },
    { link: '/contact', label: 'Contact', Component: Contact  },
    { link: '/password-reset', hideNav: true, label: 'Password Reset', Component: PasswordReset  },
   // { link: '/#second', label: 'Second', Component: React.Component  },
]