import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap';
import { useData } from '../../Data/DataContext';
import { useLayout } from '../../Layout';
import Face from '../../assets/img/facebook.svg'
import Insta from '../../assets/img/instagram.svg'

export default function BeforeContact() {
    const { contentHeight, doubleHeight: height} = useLayout()
    const { contact } = useData();
    const { beforeTitle1, beforeTitle2, beforeContent1, beforeContent2, facebook, instagram, beforeImage  } = contact;

    const sendMail = (event:React.MouseEvent)=>{
        event.preventDefault();
        const link = document.createElement('a')
        //link.target = 'blank'
        link.href=`#contact-form`
        //link.href=`mailto:${contact.contactEmail}?subject=Solicitud de contacto&body=Buen dia, deseo obtener información sobre...`
        link.click()
    }

    const faceHandler = (event:React.MouseEvent)=>{

    }

    const instHandler = (event:React.MouseEvent)=>{
        
    }

    return <Container fluid={true} className="contact-before" style={{ height: contentHeight, position: 'relative' }}>
        <div className="image" style={{top: 0}}>{ beforeImage && <img src={beforeImage} />}</div> 
        <Row className='h-100' style={{ padding: '0px'}}>            
            <Col sm={6} className="sideb p-0" >
                <div className='box'>
                    <div className="title">
                        {beforeTitle1}
                    </div>
                    <div className="description">
                        {beforeContent1}
                    </div>
                    <Button onClick={sendMail}>Envia un mensaje</Button>
                </div>
              
           
             
            </Col>
            <Col sm={6} className="sidea p-0">   
                <div className='box'>
                    <div className="title">
                    {beforeTitle2}
                </div>
                <div className="description">
                    {beforeContent2}
                </div>
                <Button><img src={Face} /> Facebook</Button>
                <Button><img src={Insta} />Instagram</Button>
                </div>
              
            </Col>
        </Row>
    </Container>
}
