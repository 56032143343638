import React from 'react'
import { useData } from '../../Data/DataContext'
import { useLayout } from '../../Layout'

export default function Availability() {

  const {availability } = useData()
  const { contentHeight: height} = useLayout()
  const {title, description} = availability
  return (
    <div style={{ height }} className="availability midsection">
        <div className='image' style={{ background: availability.color}}>
            {availability.image && <img src={availability.image} /> }
        </div>
        <div className='text-container'>
                <div className='title'>{title}</div>
                <div className='description'>{description}</div>
          </div>
    </div>
  )
}
