import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Layout from './Layout';
import Routing from './Route';
import 'bootstrap/dist/css/bootstrap.css';
import { Container } from 'reactstrap';
import { Route, Routes } from 'react-router-dom';
import { AllRoutes } from './Route/allRoutes';
import { Data } from './Data/DataContext';

ReactDOM.render(
  <React.StrictMode>
    <Data>
    <Routing>
      <Layout>
        <Routes>
            {AllRoutes.map((r, i)=><Route path={r.link} key={i} element={<r.Component />} />)}
        </Routes>
      </Layout>
    </Routing>
    </Data>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
