import { faCheck, faComputer, faMapLocation, faPaperclip, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { useData } from '../../Data/DataContext';
import { useLayout } from '../../Layout';
import Captcha from './Captcha';
import { ContactCtx, useContact } from './ContactContext';

const Contact = ()=> {
    const [sent, setSent] = useState(false)
    const { contentHeight: height } = useLayout()
    const { contact, logo } = useData();
    const { session, reload } = useContact();
    const { title, image, text } = contact;
    const [ {name, email, body, captcha}, setData] = useState({ name: '', email: '', body: '', captcha: ''})
    

    useEffect(()=>{
        setData({name: '', email: '', body: '', captcha: ''})
    },[session.s])

    const sendEmail = ()=>{
        const nl = '<br />'
        const message = `Sres. COQUETA${nl}
        ${body}${nl}
        
        Atentamente,${nl}
        ${name}${nl}
        ${email}${nl}
        `
        

        let xhr = new XMLHttpRequest();
        xhr.open('POST', process.env.REACT_APP_CAPTCHA_URL + 'sendEmail')
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('auth', session.s)
        xhr.send(JSON.stringify({
            'code': captcha,
            'fromName': name,
            'fromEmail': email,
            'message': message
        }))
        xhr.onerror = event=>{
            reload()
        }
        xhr.onloadend = event =>{
            if(xhr.status == 200 || xhr.status == 201){
                setSent(true)
            }else{
                reload()
            }
        }

    }

    const changeHandler = (event:any)=>{
        const { name:prop, value } = event.target
        const data = { name, email, body, captcha}
        setData({...data, [prop]: value})
    }

    const submit:React.FormEventHandler<HTMLFormElement> = (event)=>{
        event.preventDefault()

        

        sendEmail()
        


    }
  
    return <Container id="contact-form" fluid={true} className="contact-2" style={{ height }}>
        <Row className='h-100 p-0'>

            
            <Col sm={6} className="sideb p-0" >
              <div className='m-auto'>
              <div className='title'>{title}</div>
                  <div className='icon'>
                  <FontAwesomeIcon icon={faPhoneAlt} />
                  <FontAwesomeIcon icon={faPaperclip} />
                      <FontAwesomeIcon icon={faComputer} />
                  </div>
                  <div className='text'>
                        {text}
                  </div>
              </div>
             
            </Col>
            <Col sm={6} className="sidea -0 ">
           
                <Form className='form' onSubmit={submit}>
                    <Row>
                        <Col sm={12}>
                            <div className="form-title">
                                Envía un mensaje
                            </div>
                        </Col>
                        <Col sm={12}>
                            <FormGroup >
                                <Label>Nombre</Label>
                                <Input disabled={sent} value={name} onChange={changeHandler} name='name' />
                            </FormGroup>

                        </Col>
                        <Col sm={12}>
                            <FormGroup >
                                <Label>Correo</Label>
                                <Input disabled={sent} value={email} onChange={changeHandler} name='email' type='email' />
                            </FormGroup>

                        </Col>
                        <Col sm={12}>
                            <FormGroup >
                                
                                <Input disabled={sent}  value={body} onChange={changeHandler} name='body' type='textarea' />
                            </FormGroup>

                        </Col>
                        <Col>
                            <Captcha value={captcha} changeHandler={changeHandler} />
                        </Col>
                        <Col sm={12}>
                            <Button disabled={!session.s || sent}>Enviar</Button>
                        </Col>
                        {sent && <Col style={{ fontSize: '.8rem', color: 'green'}}>Mensaje enviado <FontAwesomeIcon icon={faCheck}/></Col> }
                    </Row>
                    
                </Form>
            
            </Col>
        </Row>
    </Container>
}


export default ()=><ContactCtx>
        <Contact></Contact>
    </ContactCtx>
