import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { useData } from '../../Data/DataContext'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useLayout } from '../../Layout'
import { BlockData } from './BlockData'

export default function Blocks() {

 
  const { blocks } = useData()
  const { contentHeight } = useLayout()

 

  return (
    <Container fluid={true} className='blocks' style={{height: contentHeight}}>
        <Row className="h-100">
            <Col sm={6} className="sidea">
            <div className="subtitle">{blocks.subtitle}</div>
                <div className="title">{blocks.title}</div>
                
            </Col>
            <Col sm={6} className="sideb">
                <div className="description" dangerouslySetInnerHTML={{ __html: blocks.description }}></div>
            </Col>
            {blocks.blocks.map((b,i)=><Col key={i} className='block-item'>
                  <InnerBlock {...b} />  
            </Col>)}
        </Row>
    </Container>
  )
}


const InnerBlock = ({image, video, description, title, icon}:BlockData)=>{
  const videoRef = React.useRef<HTMLVideoElement>(null)
                  useEffect(()=>{
                    if(videoRef.current){
                      videoRef.current.volume = 0
                      videoRef.current.loop = true
                      videoRef.current.play()
                    }
                  },[videoRef.current])
  return <>
      {!video && <div className='image'><img src={image} /></div> }
    {video && <video ref={videoRef} src={video} className="video" ></video>}
    <div className='title'>{title}</div>
    <div className='description'>{description}</div>
    <div className="icon"><FontAwesomeIcon icon={icon} /></div>
  </>

}