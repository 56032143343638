import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useData } from "../../Data/DataContext";
import { useQuery } from "../../Route";
import Activate from "./Activate";
import { PasswordContext, PasswordCtx } from "./PasswordContext";

function PasswordResetInner() {
  const [sent, setSent] = useState(false);
  const [isActivate, setIsActivate] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");

  const data = useData();
  const query = useQuery();

  const changeHandler = (event: any) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    if (error) {
      setIsActivate(false);
    }
  }, [error]);

  useEffect(() => {
    setIsActivate(!!query.get("activate"));
  }, [query]);

  const submit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    data.request
      .post("resetInternalEmail", { email })
      .then((r: any) => console.log(r))
      .then(() => setSent(true));
  };
  return (
    <div className="password-reset">
      {!isActivate && (
        <Row>
          <Form onSubmit={submit}>
            <Col sm={12}>
              <FormGroup>
                <Label for="reset-email">
                  {data.resetPassword.formLabelText}
                </Label>
                <Input
                  disabled={sent}
                  type="email"
                  onChange={changeHandler}
                  name="reset-email"
                />
              </FormGroup>
              {sent && (
                <div>
                  Revise su correo {email} e ingrese en la dirección de
                  activación
                </div>
              )}
              <Button disabled={sent}>Enviar</Button>
            </Col>
          </Form>

          <Col sm={12}>
            <div className="alert">{error}</div>
          </Col>
        </Row>
      )}
      {isActivate && (
        <Activate
          onRejectValidation={() => setError(data.resetPassword.activateError)}
          activateToken={query.get("activate")}
        />
      )}
    </div>
  );
}

export default function PasswordReset() {
  return (
    <PasswordCtx>
      <PasswordResetInner></PasswordResetInner>
    </PasswordCtx>
  );
}
