import React, { useContext, useEffect, useState } from "react"

export interface IPasswordContext {
    session: {
        s: string, 
        c: string
    },
    setData: (token:any)=>void,
    reload: ()=>void
}
export const PasswordContext = React.createContext<IPasswordContext>({
    session: { s: '', c: ''},
    setData: (token)=>{},
    reload: ()=>{}
})

export const PasswordCtx = ({children}:any)=>{
    const [session, setToken] = useState<any>({ s:'', c:''})

    const reload = async ()=>{
        const r = await fetch(process.env.REACT_APP_CAPTCHA_URL + 'getToken',{
            headers: {
                accept: 'application/json',
            }
        }).then(r=>r.json()).catch(r=>r)

        
        setToken({ s: r.t, c: r.c})

    }

    
    
    useEffect(()=>{
        
        reload()

    }, [])

    return <PasswordContext.Provider value={{session, setData: setToken, reload }}>
            {children}
    </PasswordContext.Provider>
}

export const usePassword = ()=>{
    return useContext(PasswordContext)
}