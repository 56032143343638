import React from 'react'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import { AllRoutes } from './allRoutes'

export default function Routing({children}:any) {
  return <BrowserRouter>
    {children}

  </BrowserRouter>
}


export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}