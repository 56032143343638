import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Navigation from './Navigation'
import '../assets/scss/themes.scss'
import Anouncement from '../Views/Anouncement'



export const LayoutContext = React.createContext({
 theme: "theme1",
 navHeight: 0,
 contentHeight: '',
 doubleHeight: '',
 navHide: false
})

const getHeight = (element: HTMLDivElement|null)=>{
    return element?element.getBoundingClientRect().height:0
}

export default function Layout({children}:any) {
    const ref = React.createRef<HTMLDivElement>();
    const [theme, setTheme] = useState("theme1")
    const [navHeight, setNavHeight] = useState(0);
    const contentRef = useRef<HTMLElement>()
    const navHide = useNavHide(contentRef)
    
    //

    useEffect(()=>{
        setNavHeight(getHeight(ref.current))
    
    },[getHeight(ref.current)])

    const contentHeight = 'calc(100vh - ' + navHeight + 'px)'

    const doubleHeight = 'calc(2 * 100vh - 2*' + navHeight + 'px)'
    return <LayoutContext.Provider value={{ theme, contentHeight, navHeight, doubleHeight, navHide }}>
        <div className={theme} style={{display: 'contents'}}>
           
            
            <div className="layout flex-column" ref={contentRef as any} style={{paddingTop: navHeight}}>
                <div className='row allnavs' ref={ref} >
                    <Col>
                     <Navigation />
                    </Col>
                </div>
             
                <Row className='flex-grow-1 content' >
                    <Col>  
                    {children}
                    </Col>
                </Row>
            </div>
        </div>
       
       
    </LayoutContext.Provider>
  }

export function useLayout(){
    return useContext(LayoutContext)
}

const useNavHide = (ref:React.MutableRefObject<HTMLElement | undefined>)=>{
    const [scroll, setScroll] = useState(ref.current?.scrollTop);

    useEffect(()=>{
        if(ref.current){
            const listener = (ev:Event)=>{
                 
                setScroll(ref.current?.scrollTop)
            }
            ref.current.addEventListener('scroll',listener)
            return ()=>{
                if(ref.current){
                    ref.current.removeEventListener('scroll', listener)
                }
                
            }
        }
        
    },[ref?.current])
    console.log(scroll)

    const hide = scroll? (window.innerHeight) < scroll: false
    return hide
}