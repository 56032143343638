import React, { useEffect, useState } from 'react'

export default function ValidatePassword() {
  return (
    <div>ValidatePassword</div>
  )
}


export const useValidPassword = ({password, confirmPassword}:{ password: string, confirmPassword: string})=>{    
   //Validate Letters 
   const [valid, setValid]= useState(false)
   //Minimum eight and maximum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
    let r = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    const helpText = 'Mínimo ocho y máximo 10 caracteres, al menos una letra mayúscula, una letra minúscula, un número y un carácter especial (caracteres permitidos: @$!%*?&)'
    
    useEffect(()=>{
        setValid( !!password && 
            !!confirmPassword && 
            password == confirmPassword && 
            r.test(password))
    },[password, confirmPassword])
    

    return { helpText, valid }

}